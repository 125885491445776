import React from "react"
import {Container, Navbar, Nav} from "react-bootstrap"

class HeaderNav extends React.Component{

    componentDidMount() {
        if(typeof window !== 'undefined'){
            if(window.innerWidth > 576)
                window.addEventListener('scroll', this.handleScroll)
            else {
                var header = document.getElementById("header")
                header.classList.add("header-color")
                header.classList.add("dots-before")
            }
        } 
      }
    
      componentWillUnmount() {
        if(typeof window !== 'undefined') {
            if(window.innerWidth > 576)
                window.removeEventListener('scroll', this.handleScroll)
        }
      }
    
      handleScroll = () => {
        var header = document.getElementById("header")
        if(window.scrollY > 150) { 
            header.classList.add("header-color")
            header.classList.add("dots-before")
        } else {
            header.classList.remove("header-color")
            header.classList.remove("dots-before")
        }
      }

    render(){
        return(
            <Container fluid id="header" className="position-fixed py-md-2 px-0 nav-container" style={{zIndex: 1}}>
                <Container fluid="md">
                    <Navbar collapseOnSelect expand="sm" bg="none" variant="dark">
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="mr-auto">
                            <Nav.Link href="#top">Главная</Nav.Link>
                            <Nav.Link href="#about">О нас</Nav.Link>
                            <Nav.Link href="#objects">Объекты</Nav.Link>
                            <Nav.Link href="#contacts">Контакты</Nav.Link>                
                            </Nav>
                            <Nav>
                            <Nav.Link rel="nofollow" href="tel:8(0165)64-08-17">8(0165)64-08-17</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar> 
                </Container>
            </Container>
            
        )
    }
}

export default HeaderNav