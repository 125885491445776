import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"

export const SiteMetadata = ({ description, image, title, canonical }) => {
    const {
      site: {
        siteMetadata: { siteUrl, locale, siteTitle },
      },
    } = useStaticQuery(graphql`
      query SiteMetadata {
        site {
          siteMetadata {
            siteUrl
            locale
            siteTitle: title
          }
        }
      }
    `)
  
    const urlCanonical = siteUrl + canonical
  
    return (
      <Helmet
        defer={false}
        defaultTitle={title}
        title={title}
        titleTemplate={title}
      >
        <html lang={locale} />
        <link rel="canonical" href={urlCanonical}/>
        
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={locale} />
        <meta property="og:site_name" content={siteTitle} />
        <meta property="og:image" content={image} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={urlCanonical} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:image" content={image} />
        <meta property="twitter:description" content={description} />
  
        <script type="application/ld+json">
          {`
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": 'ООО "Металл"',
                "url": "http://ooometall.by/",
                "email" : "info@ooometall.by",
                "image" : "http://ooometall.by/logo.png",              
                "telephone": "+375 165 64-08-17"
              }
            `}
        </script>
  
      </Helmet>
    )
  }