import React from "react"
import { Row, Col, Carousel } from "react-bootstrap"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import Img from "gatsby-image"


export const GalleryCert = ({images, alt}) => { 
    let subarray = []
    images.map((item, i) => {
      subarray.push(<Col key={`cert_${i}`}><Img fluid={item.node.childImageSharp.fluid} alt={alt} /></Col>) 
      return(<></>)
    })

    return (
        <GetCarousel interval={5000} slidesArray={subarray}/>
    )
}

const GetCarousel = ({slidesArray, interval}) => {
  return(
    <Carousel 
      interval={interval} 
      indicators={null}
      prevIcon={<FaChevronLeft  color="#000"/>}
      nextIcon={<FaChevronRight color="#000"/>}
      className="mx-auto"
      style={{maxWidth: '310px'}}
    >            
      {slidesArray.map((item, i) => (
          <Carousel.Item key={`carousel_${i}`}><Row>{item}</Row></Carousel.Item>
      ))}
    </Carousel>
  )
}