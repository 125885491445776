import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import {Container, Row, Col} from "react-bootstrap"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import HeaderNav from '../components/HeaderNav'

import "bootstrap/dist/css/bootstrap.css"
import "../styles/style.css"

export const Layout = ({children}) => {
    const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        meta: siteMetadata {
          links {
            contact
          }
        }
      }
      footer: file(relativePath: { eq: "images/footer.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      } 
      footerMobile: file(relativePath: { eq: "images/footer-mobile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 576) {
            ...GatsbyImageSharpFluid
          }
        }
      } 
      logo: file(relativePath: { eq: "images/logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }   
    }
  `)

  const imagesFooter = [
    data.footerMobile.childImageSharp.fluid,
    {
      ...data.footer.childImageSharp.fluid,
      media: `(min-width: 576px)`,
    }
  ]

  if (typeof window !== "undefined") {
    require('smooth-scroll')('a[href*="#"]', {
      topOnEmptyHash: false,
      speed: 900,
      speedAsDuration: true,
      easing: 'easeInOutCubic',
      offset: 75
    })
  }

  return(
      <>
        <HeaderNav/>
        {children}
        <BackgroundImage
          Tag="div"
          className={"container-fluid"}
          fluid={imagesFooter}
          style={{backgroundColor: `#00435e`}}
        >
          <Container fluid className="dots-before py-5 text-white" id="contacts">
            <Container>
              <Row>
                <Col lg={{span: 8, offset: 2}} className="mt-5">
                  <Row>
                    <Col sm className="text-center text-sm-left mb-5">
                      Общество с ограниченной ответственностью "Металл"<br/>
                      225708, г. Пинск, ул. Брестская 150<br/>
                      УНН 200188959<br/>
                      8 (0165) 64-05-82<br/>
                      8 (0165) 64-08-17 (факс/приемная)<br/>
                      <a rel="nofollow" href="mailto: info@ooometall.by" className="text-white">info@ooometall.by</a>
                    </Col>
                    <Col className="text-center d-flex flex-wrap align-items-sm-center justify-content-center">
                      <div>
                        <Img fluid={data.logo.childImageSharp.fluid} className="mx-auto mb-2" style={{maxWidth : '80px'}} alt="ООО Металл - строительная компания"/>
                        <h3 className="h1 d-inline-block border-0">Металл</h3>
                      </div>  
                    </Col>    
                  </Row>                           
                </Col>
              </Row>
            </Container>
          </Container>            
        </BackgroundImage>
      </>
  )
}