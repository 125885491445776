import React from "react"

export const SVG = ({type, style}) => {
    let svg = <></>
    switch (type) {
        case "crane":
            svg = 
            <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 55 55"  style={style}>
                <path d="M54.37,50.34V46.77a3.51,3.51,0,0,0-3.84-3.84H26.4c-3.25,0-4.93,1.55-4.93,3.84V51.4c0,2.4,1.61,3.6,4.93,3.6H50.53C53,55,54.37,53.39,54.37,50.34ZM50,50.61H25.85V47.32H50Z" fill="#37474f"/>
                <path d="M35.73,17.71v10.5h0L37.12,32H34.77L11.83,0,6.77,3.05,8.31,5.74V19.9H6.69l1.08,7.68h.54V29.9A3.35,3.35,0,0,0,6.22,33a3.25,3.25,0,0,0,1.12,2.58L1.39,40.74H3.07L8.34,36.2a4,4,0,0,0,1.49.29,3,3,0,0,0,1.46-.38L17,40.74h1.73L12.16,35.4a3.34,3.34,0,0,0,.74-2.18,1.1,1.1,0,0,0-2.19,0,1.13,1.13,0,0,1-.25.8l-.3-.24a.56.56,0,0,0-.7,0l-.41.36A1.1,1.1,0,0,1,8.42,33a1.36,1.36,0,0,1,1.16-1.27,1.1,1.1,0,0,0,.92-1.08V27.58h1.22l1.53-7.68H11.6v-8L24.76,37.27v1.28h4.39v2.19H45.6V38.55h6.58V17.71Zm-9.49,5.7v0l-4.47,2.91,2.3-5.9ZM10.5,19.9H9.4V7.8l1.1,2.07Zm.95-11.18L9.56,5.13l2.95-1.94ZM14,5.42l2.1,3.66-3.39,2.18Zm.34,7.94,3.2-2.08L16.3,17Zm4.55-.22,2.36,3.44-3.55,2.27Zm-.37,8.33,4-2.71-2.26,6ZM24.41,31.9,22.5,28.53l4.75-3.39Zm1.49,2.4,3.25-7,2.63,3.59Zm21.89-5.62H40.11V22.1h7.68Z" fill="#37474f"/>
                <polygon points="1.73 47.32 0.63 47.32 0.63 49.52 18.18 49.52 18.18 47.32 15.98 47.32 15.98 45.13 15.98 44.03 18.18 44.03 18.18 41.84 0.63 41.84 0.63 44.03 2.82 44.03 2.82 47.32 1.73 47.32" fill="#37474f"/>
            </svg>           
            break;
        case "attestate":
            svg = 
            <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 55 55" style={style}>
                <path d="M53.17,29.79H48.58v3.67L44,28.88l-11,11V54.54h8.25V42.62h5.5V54.54H55V39.88L53.17,38Z" fill="#37474f"/>
                <path d="M3.67,54.54H7.33V6H3.67A3.67,3.67,0,0,1,0,2.29V50.87A3.67,3.67,0,0,0,3.67,54.54Z" fill="#37474f"/>
                <path d="M3.67,4.13h5.5V46.29h22V39.12L44,26.28,46.75,29V28h6.42V.46H3.67a1.84,1.84,0,1,0,0,3.67ZM17.42,43.54H15.58V41.71h1.84Zm3.66,0H19.25V41.71h1.83ZM48.58,3.21V22.46H36.67V19.71h3.66v-.92a4.59,4.59,0,0,0-3.66-4.49V3.21ZM13.75,16h7.33V14.21H13.75v-11h11v11h-.92V16h2.75V3.21h8.25V16h.92a2.75,2.75,0,0,1,2.59,1.84H34.83v4.58h-5.5V36.21H22.92V32.54a3.67,3.67,0,0,0-3.67,3.67h-5.5ZM11.92,41.71h1.83v1.83H11.92Z" fill="#37474f"/>
            </svg>
            break;
        case "certificate":
            svg = 
            <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 55 55"  style={style}>
                <circle cx="27.55" cy="37.22" r="5.37" fill="#37474f"/>
                <path d="M53.39,0H1.61A1.61,1.61,0,0,0,0,1.61V40.28a1.61,1.61,0,0,0,1.61,1.61H20.34a8.59,8.59,0,1,1,14.42,0H53.39A1.61,1.61,0,0,0,55,40.28V1.61A1.61,1.61,0,0,0,53.39,0ZM21.05,6.55H34a1.61,1.61,0,0,1,0,3.22H21.05a1.61,1.61,0,1,1,0-3.22ZM43.61,25.89H11.39a1.62,1.62,0,0,1,0-3.23H43.61a1.62,1.62,0,0,1,0,3.23Zm0-6.45H11.39a1.61,1.61,0,1,1,0-3.22H43.61a1.61,1.61,0,1,1,0,3.22Z" fill="#37474f"/>
                <path d="M21.14,42.93V53.39a1.61,1.61,0,0,0,2.5,1.34l4-2.63,4,2.63a1.61,1.61,0,0,0,2.5-1.34V42.81a8.58,8.58,0,0,1-12.94.12Z" fill="#37474f"/>
            </svg>
            break;
        case "expiriance":
            svg = 
            <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 55 55"  style={style}>
                <path d="M15.58,36.51v8.41H24Z" fill="#37474f"/>
                <path d="M50.42,1.83V4.58h2.75Z" fill="#37474f"/>
                <path d="M52.25,17.42H55v1.83H52.25Z" fill="#37474f"/>
                <path d="M52.25,22H55v1.83H52.25Z" fill="#37474f"/>
                <path d="M52.25,26.58H55v1.84H52.25Z" fill="#37474f"/>
                <path d="M52.25,31.17H55V33H52.25Z" fill="#37474f"/>
                <path d="M52.25,35.75H55v1.83H52.25Z" fill="#37474f"/>
                <path d="M52.25,40.33H55v1.84H52.25Z" fill="#37474f"/>
                <path d="M39.15,50.15l-1.3-1.3L39.69,47l-1.46-1.46L36.4,47.4l-1.3-1.3,1.84-1.83-1.46-1.46-1.83,1.84-1.3-1.3,1.84-1.83-1.46-1.46L30.9,41.9l-1.3-1.3,1.84-1.83L30,37.31l-1.83,1.84-1.3-1.3L28.69,36l-1.46-1.46L25.4,36.4l-1.3-1.3,1.84-1.83-1.46-1.46-1.83,1.84-1.3-1.3,1.84-1.83-1.46-1.46L19.9,30.9l-1.3-1.3,1.84-1.83L19,26.31l-1.83,1.84-1.3-1.3L17.69,25l-1.46-1.46L14.4,25.4l-1.3-1.3,1.84-1.83-1.46-1.46-1.83,1.84-1.3-1.3,1.84-1.83-1.46-1.46L8.9,19.9,7.6,18.6l1.84-1.83L8,15.31,6.15,17.15l-1.3-1.3L6.69,14,0,7.33V55H47.67L41,48.31Zm-35.48,3H1.83V51.33H3.67Zm3.66,0H5.5V51.33H7.33Zm3.67,0H9.17V51.33H11ZM8.25,46.75V26.58L28.42,46.75Z" fill="#37474f"/>
                <path d="M48.58,0h-33V20.32l4.59,4.59V11l2.75-5.5L25.67,11V30.41l7.4,7.4-1-9.39,1.84-5.5-2.75-4.59,3.66-5.86v5.86h1.84V12.47l3.66,5.86-2.75,4.59,1.84,5.5-1.5,14.24L42,46.75H55V44.92H45.83V14.67H55V6.42H48.58Z" fill="#37474f"/>
            </svg>
            break; 
            
            
        case "microautobus":
            svg = 
            <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 56 35" style={style}>
                <path d="M11.27,23.48a5.76,5.76,0,0,0,0,11.52,5.76,5.76,0,0,0,0-11.52Zm0,8.19a2.43,2.43,0,1,1,2.35-2.43A2.39,2.39,0,0,1,11.27,31.67Z" fill="#37474f"/>
                <path d="M44.73,23.48a5.76,5.76,0,1,0,5.57,5.76A5.67,5.67,0,0,0,44.73,23.48Zm0,8.19a2.43,2.43,0,1,1,2.35-2.43A2.39,2.39,0,0,1,44.73,31.67Z" fill="#37474f"/>
                <path d="M55,14.5l-4.89-2.33L44.69,1l-1.6-1H1.79L0,1.85V28.43l1.79,1.85H4A7.71,7.71,0,0,1,3.91,29a7.49,7.49,0,0,1,7.36-7.61A7.49,7.49,0,0,1,18.63,29a7.71,7.71,0,0,1-.1,1.28H37.47a7.71,7.71,0,0,1-.1-1.28,7.36,7.36,0,1,1,14.72,0A7.71,7.71,0,0,1,52,30.28h2.23L56,28.43V16.18ZM13.3,13.56H7.05V5.84H13.3Zm11.45,0H18.5V5.84h6.25Zm5.56,0V5.84h9.38l3.73,7.72Z" fill="#37474f"/>
            </svg>
            break;
        case "car":
            svg = 
            <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 56 35" style={style}>
                <path d="M49.12,19.44a5.54,5.54,0,0,0-.53-2.35,5.41,5.41,0,0,0-.12-.55L44.8,7.44l0-.08C43.49,3.54,41.66,0,37,0H19.32c-4.74,0-6.42,3.63-7.73,7.34L7.75,16.45a5.58,5.58,0,0,0-.87,3v2.83A5.4,5.4,0,0,0,7,23.55a2.15,2.15,0,0,0-.15.8v8.42A2.23,2.23,0,0,0,9.11,35h4.64A2.23,2.23,0,0,0,16,32.77V28.56h24.3v4.21A2.23,2.23,0,0,0,42.51,35h4.38a2.23,2.23,0,0,0,2.23-2.23V24.35a2.15,2.15,0,0,0-.15-.8,5.4,5.4,0,0,0,.15-1.28V19.44ZM14.31,8.25c1.46-4.12,2.64-5.38,5-5.38H37c2.36,0,3.67,1.41,5,5.36l2.68,5.43a8.33,8.33,0,0,0-2.85-.5H14.13a8.06,8.06,0,0,0-2.58.41Zm2.3,15.1H11.72a1.6,1.6,0,1,1,0-3.2h4.89a1.6,1.6,0,0,1,0,3.2ZM33.89,23H22.51a1,1,0,0,1,0-2H33.89a1,1,0,0,1,0,2Zm10.27.32H39.27a1.6,1.6,0,1,1,0-3.2h4.89a1.6,1.6,0,0,1,0,3.2Z" fill="#37474f"/>
            </svg>
            break;
        case "bolshegruz":
            svg = 
            <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 56 35" style={style}>
                <path d="M23,23.47a5.73,5.73,0,0,0-4.19,1.82h0a5.76,5.76,0,1,0,0,7.9A5.76,5.76,0,1,0,23,23.47Z" fill="#37474f"/>
                <path d="M42.25,23.47a5.76,5.76,0,1,0,5,8.63A5.77,5.77,0,0,0,42.25,23.47Z" fill="#37474f"/>
                <path d="M54.83,18.33a1.59,1.59,0,0,0-.13-.61c0-.08-1.88-3.69-5.18-10.16a1.58,1.58,0,0,0-1.4-.85H39.74a1.57,1.57,0,0,0-1.57,1.57v1.78h-4l1.72-6.92h5.48a1.57,1.57,0,1,0,0-3.14H2.85A1.58,1.58,0,0,0,1.28,1.73L2.47,13.62H28.1a1.58,1.58,0,1,1,0,3.15H2.79l.14,1.45L1.22,25a1.56,1.56,0,0,0,.35,1.43L5,30.28a1.56,1.56,0,0,0,.78.48,8.92,8.92,0,0,1,13-9.38,8.92,8.92,0,0,1,13,9.43h1.75a8.91,8.91,0,1,1,17.54,0,1.53,1.53,0,0,0,.69-.39l2.62-2.51a1.59,1.59,0,0,0,.49-1.14ZM38.17,16.77H32.53l.89-3.57h4.75Z" fill="#37474f"/>
            </svg>
            break;
        case "smesitel":
            svg = 
            <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 56 35" style={style}>
                <path d="M7.31,8.9l1.6,1.85,2.38,2.8,1.59,1.86,2.57,3A5.59,5.59,0,0,0,17,19.71l4.09,2.36a8,8,0,0,0,4,1.1c.25,0,.52,0,.78,0l4.46-.44h0A.78.78,0,0,0,31,22.3L32,20.66l2.39-4.14L35.81,14a.42.42,0,0,0,0-.1.75.75,0,0,0,0-.62L34,9.2a8.1,8.1,0,0,0-3.32-3.65L26.57,3.18a6.3,6.3,0,0,0-1.91-.71L11.43,0a.79.79,0,0,0-.83.38l-4,6.89a.8.8,0,0,0,.09.92Z" fill="#37474f"/>
                <path d="M51.06,22.25,45,16a3.47,3.47,0,0,0-2.17-.91H38.34a.8.8,0,0,0-.79.8v8.75H36V16.94L32.41,23.1a2.38,2.38,0,0,1-1.84,1.17h-.18v.37H26.58s-2.31.06-2.75,0H12.88V17.85L7.69,11.79l-.38-.45V24.66H6.52a.81.81,0,0,0-.76.53L4.35,29.43,4.17,30a.79.79,0,0,0,.11.71.8.8,0,0,0,.65.34H8.11a4,4,0,0,0,8,0h1.59a4,4,0,1,0,8,0H39.93a4,4,0,0,0,8,0h1.59a2.12,2.12,0,0,0,2.3-1.59,3.55,3.55,0,0,0,.09-.79v-4.4A2.87,2.87,0,0,0,51.06,22.25Zm-39,11.16A2.39,2.39,0,1,1,14.47,31,2.39,2.39,0,0,1,12.09,33.41Zm9.55,0A2.39,2.39,0,1,1,24,31,2.38,2.38,0,0,1,21.64,33.41Zm22.27,0A2.39,2.39,0,1,1,46.3,31,2.38,2.38,0,0,1,43.91,33.41Zm-.47-11.14a1.13,1.13,0,0,1-1.12-1.12V16.7h.48a1.87,1.87,0,0,1,1,.44l5,5.13Z" fill="#37474f"/>
            </svg>
            break;
        case "autokrane":
            svg = 
            <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 56 35" style={style}>
                <path d="M6.49,30H4.87a.68.68,0,0,1-.67-.67v-2a.68.68,0,0,1,.67-.67H8.36A5.5,5.5,0,0,0,6.49,30ZM51.8,27.33v2a.68.68,0,0,1-.67.67H45.7a5.49,5.49,0,0,0-10.88,0H17.39a5.49,5.49,0,0,0-1.86-3.38h3.78L16,7.52A3.75,3.75,0,0,1,12.89,7V9.67a2.86,2.86,0,1,1-3.54,2.77.69.69,0,0,1,.69-.69.68.68,0,0,1,.68.69A1.49,1.49,0,1,0,12.21,11a.69.69,0,0,1-.69-.69v-5a3.77,3.77,0,0,1-.29-1.46,3.82,3.82,0,1,1,7.64,0,3.87,3.87,0,0,1-.32,1.52L32.41,26.26V7.51a1.33,1.33,0,0,1,1.33-1.33h6.33A5.34,5.34,0,0,1,44.5,8.53l4.08,6a5.35,5.35,0,0,1,.92,3v9.1h1.63A.68.68,0,0,1,51.8,27.33ZM17,3.83a1.91,1.91,0,1,0-1.91,1.91A1.91,1.91,0,0,0,17,3.83ZM22,26.66h7.43l-10-15.16ZM45,14.73l-3.26-4.64a.7.7,0,0,0-.55-.28H36.11a.67.67,0,0,0-.66.67v4.63a.67.67,0,0,0,.66.67h8.35A.67.67,0,0,0,45,14.73Zm-.58,16.11a4.17,4.17,0,1,1-4.16-4.18A4.17,4.17,0,0,1,44.43,30.84Zm-2.08,0a2.09,2.09,0,1,0-2.08,2.08A2.08,2.08,0,0,0,42.35,30.84Zm-26.24,0A4.17,4.17,0,1,1,12,26.66,4.16,4.16,0,0,1,16.11,30.84Zm-2.08,0A2.09,2.09,0,1,0,12,32.92,2.08,2.08,0,0,0,14,30.84Z" fill="#37474f"/>
                </svg>
            break;
        case "exkavator":
            svg = 
            <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 56 35" style={style}>
                <path d="M46.64,6.3a1.84,1.84,0,0,0-1.5-.78H38.06a1.86,1.86,0,0,0-1.71,1.15l-4.8,11.86H27.23L25.42,1.65A1.84,1.84,0,0,0,23.59,0H4.26a1.85,1.85,0,0,0,0,3.69h.81v15a1.84,1.84,0,0,0-2.65,1.65v4.06a1.85,1.85,0,0,0,1.05,1.66,6.18,6.18,0,0,1,4.24-1.68H27.86l-.23-2.2h5.16a1.84,1.84,0,0,0,1.71-1.15L39.3,9.21h4.89l1.21,1.7h4.53Zm-35.7,8.51H8.76V3.69h2.18Zm3.68,0V3.69h7.31l1.2,11.12Z" fill="#37474f"/>
                <path d="M45.77,12.75h0v4.7a16.23,16.23,0,0,1,7.81,7.25V12.75Z" fill="#37474f"/>
                <path d="M39,26.26H7.71a4.37,4.37,0,0,0,0,8.74H39a4.37,4.37,0,1,0,0-8.74ZM8.13,32.39A1.77,1.77,0,1,1,9.9,30.63,1.76,1.76,0,0,1,8.13,32.39Zm30.68,0a1.77,1.77,0,1,1,1.77-1.76A1.76,1.76,0,0,1,38.81,32.39Z" fill="#37474f"/>
            </svg>
            break;
        default:
            break;
    }

    return svg
  }