import React from "react"
import { YMaps, Map, Placemark, Clusterer } from 'react-yandex-maps';

export const MapYaObjects = () => {
    const buildMark = [
        {
            geometry: [54.490371, 30.438919],
            properties: {
                balloonContentHeader: 'Продовольственный магазин',
                balloonContentBody: 'ул. Соляникова 2а',
                balloonContentFooter: '810 м2'
            }
        },
        {
            geometry: [55.288988, 29.629313],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'ул. Юбилейная 10',
                balloonContentFooter: '953 м2'
            }
        },
        {
            geometry: [53.620315, 23.815964],
            properties: {
                balloonContentHeader: 'Торговый объект питания',
                balloonContentBody: 'микрорайон "Южный-2", позиция по генплану №105',
                balloonContentFooter: '3286 м2'
            }
        },
        {
            geometry: [53.619808, 23.803352],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'микрорайон "Южный-5", позиция по генплану №78',
                balloonContentFooter: '1038 м2'
            }
        },
        {
            geometry: [53.117243, 26.029548],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'ул. Советская 121В',
                balloonContentFooter: '1038 м2'
            }
        },
        {
            geometry: [53.115396, 26.016170],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'пересечение улиц Коммунистическая и Куйбышева',
                balloonContentFooter: '933,6 м2'
            }
        },
        {
            geometry: [52.751470, 26.427510],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'ул. Строителей 6',
                balloonContentFooter: '1038 м2'
            }
        },
        {
            geometry: [54.273640, 30.997510],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'ул. Вокзальная',
                balloonContentFooter: '1659,9 м2'
            }
        },
        {
            geometry: [53.222385, 26.665501],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'ул. 1-ое Мая',
                balloonContentFooter: '1659,9 м2'
            }
        },
        {
            geometry: [53.150040, 29.247496],
            properties: {
                balloonContentHeader: 'Магазин',
                balloonContentBody: 'ул. Урицкого 173',
                balloonContentFooter: '1051,2 м2'
            }
        },
        {
            geometry: [51.782671, 30.265877],
            properties: {
                balloonContentHeader: 'Магазин',
                balloonContentBody: 'ул. Гагарина 50А',
                balloonContentFooter: '405 м2'
            }
        },
        {
            geometry: [53.941082, 25.756020],
            properties: {
                balloonContentHeader: 'Магазин',
                balloonContentBody: 'ул. Пески 2',
                balloonContentFooter: '1058 м2'
            }
        },
        {
            geometry: [53.135708, 30.800924],
            properties: {
                balloonContentHeader: 'Магазин',
                balloonContentBody: 'ул. Ленина 38',
                balloonContentFooter: '1058 м2'
            }
        },
        {
            geometry: [53.895021, 30.268527],
            properties: {
                balloonContentHeader: 'Торговый центр',
                balloonContentBody: 'ул. Ямницкая',
                balloonContentFooter: '1066 м2'
            }
        },
        {
            geometry: [54.433858, 25.962515],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'ул. Восточная 29А',
                balloonContentFooter: '908 м2'
            }
        },
        {
            geometry: [54.422083, 25.957970],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'ул. Борунская 49',
                balloonContentFooter: '1066 м2'
            }
        },
        {
            geometry: [53.104167, 30.063240],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'ул. Богатырёва 139А',
                balloonContentFooter: '1038 м2'
            }
        },
        {
            geometry: [54.484623, 26.380978],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'ул. Советская 73',
                balloonContentFooter: '1038 м2'
            }
        },
        {
            geometry: [53.202259, 29.217606],
            properties: {
                balloonContentHeader: 'Торговый центр',
                balloonContentBody: 'ул. Строителей 88',
                balloonContentFooter: '1038 м2'
            }
        },
        {
            geometry: [53.069045, 25.316124],
            properties: {
                balloonContentHeader: 'Торговый центр',
                balloonContentBody: 'Коссовский тракт',
                balloonContentFooter: '810 м2'
            }
        },
        {
            geometry: [52.128703, 26.111384],
            properties: {
                balloonContentHeader: 'Торговый склад',
                balloonContentBody: 'ул. Рокоссовского 2Б',
                balloonContentFooter: '960 м2'
            }
        },
        {
            geometry: [52.070159, 23.735492],
            properties: {
                balloonContentHeader: 'Продовольственный магазин',
                balloonContentBody: 'ул. Рябиновая 32А'
            }
        },
        {
            geometry: [52.189830, 25.123821],
            properties: {
                balloonContentHeader: 'Торговый центр',
                balloonContentBody: 'ул. Шоссейная 37'
            }
        },
        {
            geometry: [52.151794, 25.552382],
            properties: {
                balloonContentHeader: 'Торговый центр',
                balloonContentBody: 'ул. Советская 2Г'
            }
        },
        {
            geometry: [52.477222, 25.180311],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'пр. Мира'
            }
        },
        {
            geometry: [52.709364, 25.324756],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'ул. Депутатская 47'
            }
        },
        {
            geometry: [55.120921, 26.812344],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'ул. Зелёная 5В'
            }
        },
        {
            geometry: [54.883892, 27.771924],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'ул. Гайдара 62'
            }
        },
        {
            geometry: [54.883566, 28.697798],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'ул. Войкова 124А'
            }
        },
        {
            geometry: [55.506750, 28.697894],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'ул. Молодёжная 186Б'
            }
        },
        {
            geometry: [55.288988, 29.629313],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'ул. Юбилейная 10'
            }
        },
        {
            geometry: [55.451575, 29.982005],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'ул. Комсомольская 31А'
            }
        },
        {
            geometry: [53.833359, 28.978511],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'ул. Дзержинского 1'
            }
        },
        {
            geometry: [53.272736, 29.482402],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'ул. Гагарина 74'
            }
        },
        {
            geometry: [52.227214, 27.485699],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'ул. Первомайская 17В'
            }
        },
        {
            geometry: [52.259214, 26.819993],
            properties: {
                balloonContentHeader: 'Объект торговли',
                balloonContentBody: 'ул. Бохоново 11В'
            }
        }
    ]

    const inProcessMark = [
        {
            geometry: [52.126402, 26.029334],
            properties: {
                balloonContentHeader: 'Производственное предприятие',
                balloonContentBody: 'ул. Козубовского 13А',
                balloonContentFooter: '3384 м2'
            }
        },
        {
            geometry: [53.415429, 24.535182],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'ул. 30 лет ВЛКСМ',
                balloonContentFooter: '960 м2'
            }
        },
        {
            geometry: [54.478843, 30.315563],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'ул. Оршанская',
                balloonContentFooter: '960 м2'
            }
        },
        {
            geometry: [53.265605, 24.418768],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'пересечение ул. Строителей и ул. Солнечной',
                balloonContentFooter: '960 м2'
            }
        },
        {
            geometry: [54.816671, 29.696205],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'пересечение ул. Азгура и пер. Космонавтов',
                balloonContentFooter: '837 м2'
            }
        },
        {
            geometry: [53.619802, 23.810152],
            properties: {
                balloonContentHeader: 'Торговый объект',
                balloonContentBody: 'микрорайон Ольшанка-5, поз. №84',
                balloonContentFooter: '819 м2'
            }
        }
    ]

    return (
        <YMaps
            enterprise
            query={{
                lang: "ru_RU",
                apikey: "5f75febb-693a-4f3a-aded-31c4d5e30237"
            }}
        >
              <Map defaultState={{ center: [53.90, 27.57], zoom: 6 }} className="w-100 h-100">              
                <Clusterer
                options={{
                  preset: 'islands#nightClusterIcons',
                  groupByCoordinates: false,
                }}
                >

                  {buildMark.map((mark, i) =>(
                      <Placemark                         
                        {...mark}
                        key={`markbuild_${i}`}
                        options={{
                        preset: 'islands#dotIcon',
                        iconColor: '#466674'
                        }} 
                        modules= {
                        ['geoObject.addon.balloon']
                        }                         
                       />
                    ))}   
                    {inProcessMark.map((mark, i) =>(
                      <Placemark
                      {...mark}
                      key={`markprocess_${i}`} 
                      options={{
                        preset: 'islands#dotIcon',
                        iconColor: '#e4ca5f'
                      }} 
                      modules= {
                        ['geoObject.addon.balloon', 'geoObject.addon.hint']
                    }                         
                    />
                  ))}                
                </Clusterer>                
              </Map>
            </YMaps>
    )
}