import React, {useState} from "react"
import { Row, Col, Carousel, Modal } from "react-bootstrap"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import Img from "gatsby-image"


export const GalleryCarousel = ({images, alt}) => { 
    let subarray = []
    images.map((item, i) => {
      subarray.push(<Col key={`gallery_${i}`} onClick={() => asdasd(i)}><Img fluid={item.node.childImageSharp.fluid} alt={alt} /></Col>) 
      return(<></>)
    })
    let slidesArray = []

    let width = typeof window !== 'undefined' ? window.innerWidth : 1200

    let colSize = 1
    if( width >= 575) colSize = width < 992 ? 2 : 3
    
    for (let i = 0; i <Math.ceil(subarray.length/colSize); i++){
        slidesArray[i] = subarray.slice((i*colSize), (i*colSize) + colSize);
    }
    function asdasd(i){
      setModalIndex(i)
      setModalShow(true)
    }

    const [modalShow, setModalShow] = useState(false)
    const [modalIndex, setModalIndex] = useState(10)

    return (
      <>
        <GetCarousel interval={5000} slidesArray={slidesArray}/>        
        <MyVerticallyCenteredModal
          show={modalShow}
          images={subarray}
          index={modalIndex}
          onHide={() => setModalShow(false)}
        />
      </>
    )
}

const GetCarousel = ({slidesArray, interval, index}) => {
  return(
    <Carousel 
      interval={interval} 
      indicators={null}
      defaultActiveIndex={index} 
      prevIcon={<FaChevronLeft/>}
      nextIcon={<FaChevronRight/>}
    >            
      {slidesArray.map((item, i) => (
          <Carousel.Item key={`carousel_${i}`}><Row>{item}</Row></Carousel.Item>
      ))}
    </Carousel>
  )
}

function MyVerticallyCenteredModal({show, onHide, images, index}) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-0">
        <GetCarousel slidesArray={images} index={index}/>
      </Modal.Body>
    </Modal>
  );
}